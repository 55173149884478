<template>
  <div>
    <!-- <div style="background: white; min-height: 750px; width: 100%;"> -->
    <template v-if="d_showWdmr">
      <wisdom-data-show :change="d_wisdomData.change" :view_mode="'table'" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data[d_wisdomData.data_type]"></wisdom-data-show>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12" style="text-align: center;">
          {{ d_loadingText }}
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import WmanagerService from '@/services/wmanager';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'WdmrDataShow',
  computed: {
    ...mapGetters({})
  },
  components: {
    WisdomDataShow
  },
  mounted: function () {
    if (this.$route.query.layer_id !== undefined) {
      this.layer_id = this.$route.query.layer_id;
    }
    if (this.$route.query.wdmr_id !== undefined) {
      this.wdmr_id = this.$route.query.wdmr_id;
    }
    if (this.$route.query.wdmr_token !== undefined) {
      this.wdmr_token = this.$route.query.wdmr_token;
    }
    this.f_getWdmrData();
  },
  data () {
    return {
      d_loadingText: '',
      d_showWdmr: false,
      layer_id: '',
      wdmr_id: '',
      wdmr_token: '',
      d_selectedWdmrData: '',
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      }
    };
  },
  created: function () {},
  methods: {
    f_getWdmrData: function () {
      this.d_loadingText = 'Veri modülünüz hazırlanıyor...';
      let query = 'layer_id=' + this.layer_id;
      query += '&wdmr_id=' + this.wdmr_id;
      if (this.wdmr_token) {
        query += '&wdmr_token=' + this.wdmr_token;
      }
      // console.log(query);
      WmanagerService.get_layer_wdmr_and_wdm(query)
        .then(resp => {
          if (resp.data.status_code === "3000") {
            // console.log(resp.data);
            this.d_wisdomData.wisdom_data = resp.data.wdmr;
            this.d_wisdomData.data_type = this.d_wisdomData.wisdom_data.type;
            this.d_wisdomData.option_data[this.d_wisdomData.data_type] = resp.data.layer_wdm;
            this.d_showWdmr = true;
          } else {
            this.d_wisdomData.wisdom_data = '';
            this.d_loadingText = resp.data.status_message + ' (' + resp.data.status_code + ')';
            console.log('errorr f_getWdmrData : ', resp.data);
          }
        });
    }
  }
};

</script>

